@import 'assets/scss/overrides.scss';

.account-mapper {
  h1 {
    font-size: $spacing-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $spacing-large;
  }

  .accounts-selector {
    display: flex;
    flex-wrap: wrap;
    .account {
      flex: 0 1 33%;
      button {
        min-width: 150px;
        min-height: 150px;
      }
    }
  }

  li {
    list-style: none;
    margin-bottom: $spacing-small;
  }

  .map-button {
    margin-left: $spacing-regular;
  }

  .accounts-with-budgets,
  .accounts-with-no-budgets {
    .dropdown-header {
      padding: 0px;
    }

    input,
    select {
      font-size: 13px;
    }
  }

  .current-allocations {
    border: 2px solid #a1cc63;
    background-color: #fff !important;
  }
}
.account-allocation {
  .react-datepicker {
    position: absolute;
    z-index: 1;
  }
}

table td table {
  background-color: #f5f5f5 !important;
  box-shadow: 0 4px 15px -2px rgba(0, 0, 0, 0.15);
  th {
    text-align: center;
  }
}

.btn.btn-remap {
  background-color: #6283ba;
  color: #fff;
  margin-right: 10px;
}
.btn.btn-detail {
  background-color: #a1cd64;
  color: #fff;
}

.table-accounts {
  table-layout: fixed;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  font-size: 12px;
  button {
    background-color: #fff;
    height: 35px;
    width: 35px;
  }
}

.btn-date {
  display: block;
  margin: 0 auto;
}

tfoot {
  tr {
    background-color: #fff;
  }
  .updated-at {
    font-size: 10px;
    span {
      font-weight: 600;
    }
  }
}

.account-number-select {
  font-size: 13px;
}

.text-exceeded-error {
  color: red;
  font-size: 14px;
  text-wrap: nowrap;
}

.text-exceeded-warning {
  color: orange;
  font-size: 14px;
  text-wrap: nowrap;
}
