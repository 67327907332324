footer {
  padding: 12px 0;
  text-align: center;
}

.copyright {
  font-size: 10px;
  color: #999;
}

.divider {
  border-left: 1px solid #ccc;
  height: 100px;
}

.aws-logo {
  width: 140px;
  text-align: center;
  display: inline-block;
}
