@import 'assets/scss/variables';

.main {
  .tab-content {
    background-color: $background-white;
    padding-top: $spacing-xl;
    border-radius: 10px 0 10px 10px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  }

  .page-title {
    color: $green;
    font-weight: $font-weight-bolder;
    font-size: $font-size-lg;
  }
}
