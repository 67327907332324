.semi-circular-progressbar-wrapper {
  height: calc(var(--radio) * 1px);
  width: calc(var(--radio) * 2px);
}

.semi-circular-progressbar-wrapper.circled {
  height: calc(var(--radio) * 2px);
  width: calc(var(--radio) * 2px);
}

.semi-circular-progressbar {
  height: calc(var(--radio) * 2px);
  width: calc(var(--radio) * 2px);
  position: relative;

  svg {
    height: 100%;
    width: 100%;
    transform: rotate(180deg);
  }

  circle {
    fill: none;
    stroke: #eef2f7;
    stroke-width: calc(var(--stroke) * 1px);
    stroke-dasharray: var(--perimeter);
    stroke-linecap: round;
    stroke-dashoffset: calc(var(--perimeter) * 0.5px);
  }

  circle.progressbar {
    stroke: url('#gradient-color');
    stroke-dashoffset: calc(var(--perimeter) * 1px - (var(--perimeter) * var(--percent)) * 0.005px);
    animation: anim 0.8s linear alternate forwards;
  }

  .dot {
    position: absolute;
    inset: 0;
    z-index: 10;
    transform: rotate(calc(1.8deg * var(--percent)));
    animation: rotateAnimation 0.8s linear forwards;
  }

  .dot::before {
    content: '';
    width: calc(var(--stroke) * 1px);
    height: calc(var(--stroke) * 1px);
    position: absolute;
    top: 50%;
    left: calc(var(--stroke) * -0.2px);
    transform: translateY(-50%);
    border-radius: 50%;
    background: transparent;
    border: 1px solid #fff;
    border-width: calc(var(--stroke) * 0.2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.22);
    box-sizing: content-box;
  }

  .content {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    font-size: calc(var(--radio) * 0.35px);
    font-weight: bold;
  }

  .circled-content {
    position: absolute;
    top: calc(var(--radio) * 0.35px);
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.22);
    width: calc(var(--radio) * 1.3px);
    height: calc(var(--radio) * 1.3px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--radio) * 0.35px);
    font-weight: bold;
  }
}

@keyframes anim {
  0% {
    stroke-dashoffset: var(--perimeter);
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(calc(1.8deg * var(--percent)));
  }
}
