.config-section {
  .alert {
    font-size: 12px;
  }
  input {
    width: 100%;
    padding: 6px 8px;
    border: 2px solid #eee;
    font-size: 12px;
    outline: 0;
    border-radius: 0;
  }

  input:focus,
  input:focus-visible {
    border: 2px solid #6283ba;
  }

  input.has-error {
    border-color: #df4a3e !important;
    border-left: 4px solid #df4a3e !important;
  }

  .has-error .dropdown__control {
    border-color: #df4a3e;
    border-left: 4px solid #df4a3e !important;
  }

  .dropdown__control {
    border: 2px solid #eee;
    border-radius: 0;
    box-shadow: none;
    min-height: 34px;
    font-size: 12px;

    &:hover {
      border-color: #eee;
    }

    &:focus {
      border-color: #6283ba;
    }

    .dropdown__indicators {
      min-height: 30px;
    }

    .dropdown__indicator {
      padding: 0 8px;
    }
  }
}

.dropdown__menu-list {
  font-size: 12px;
}

.text-error {
  color: #df4a3e;
  font-size: 12px;
}

table.table-form {
  font-size: 12px;
  border: 0;
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #dee2e6 !important;

  td,
  th {
    background-color: white;
    border-color: #dee2e6;
    border-bottom: 0;
  }
  th,
  td {
    border-top: 1px solid #dee2e6 !important;
    &:first-child {
      border-left: 1px solid #dee2e6;
    }
  }

  td.role-fields {
    width: 430px;
    min-width: 430px;
  }
  td.short-fields {
    width: 60px;
  }

  input {
    width: 100%;
    padding: 6px 8px;
    border: 2px solid #eee;
    font-size: 12px;
    outline: 0;
    border-radius: 0;
  }

  input:focus,
  input:focus-visible {
    border: 2px solid #6283ba;
  }
}

.table-scrollable {
  max-height: 676px;
  overflow-y: auto;
}

.form-control {
  outline: 0;
  box-shadow: none !important;
}
