$blue-10: #eef2f7;
$blue-100: #6283ba;
$blue-200: #3b70b2;
$black-2: #383838;
$white: #ffffff;
$green: #a1cd64;
$yellow: #f4e95a;

$red: #df4a3e;
$orange: #f0a03f;
$orange-30: #fbe3c5;
$black-85: #000000;
$gray-80: #d0daea;

$primary-color: #282c34;
$secondary-color: #61dafb;
$background-blue: $blue-10;
$background-white: $white;

$spacing-small: 4px;
$spacing-regular: 8px;
$spacing-medium: 12px;
$spacing-large: 16px;
$spacing-xl: 24px;
$spacing-xxl: 32px;
$spacing-xxxl: 36px;
$spacing-56: 56px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$font-size-lg: 2.25rem;
$font-size-regular: 12px;
$font-size-medium: 14px;
$font-size-large: 16px;
$font-size-xlarge: 18px;
$font-size-xxlarge: 24px;
$font-size-xxxlarge: 28px;
