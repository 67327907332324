@import 'assets/scss/variables';

.top-nav {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  background-color: #fff;
}

.display {
  display: flex;
  flex-direction: column;
}

.name {
  text-align: end;
}

.info-avatar {
  display: flex;
  flex-direction: column;
  align-items: end;
  font-size: 12px;

  span:first-child {
    font-weight: 700;
  }

  span:last-child {
    font-size: 12px;
  }
}

.nav-menu {
  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-item {
    font-size: 14px;
    font-weight: 300;
    color: #878787;
  }

  .dropdown-menu {
    top: 100%;
    left: -100px !important;
    left: auto;
    margin-top: 0.125rem;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #61dafb;
  }
}

.info-app {
  font-size: 12px;
  padding: 0px 20px;
  color: #878787;
  white-space: nowrap;
  p {
    margin: 5px 0;
  }
}

.info-app a {
  font-size: 12px;
  color: #878787;
  text-decoration: none;
}

.nav-menu-icon {
  width: 16px;
  vertical-align: middle;
}

.theme-button {
  display: flex;
  background-color: transparent;
  border: 0;
  color: #878787;
  font-size: 12px;
  padding: 0 20px;
  gap: 10px;
  img {
    width: 16px;
  }
}
