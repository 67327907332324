@import 'assets/scss/variables';

.products {
  .budget {
    min-width: 15rem;
    max-width: 300px;
    border-radius: 10px;
    padding: 16px 0;
    position: relative;
    border-width: 4px;

    .budget-info {
      .divisions {
        width: 40%;
      }
    }

    .text-ellipsis {
      max-width: 200px;
    }

    .header {
      font-weight: $font-weight-bolder;
    }

    .details {
      font-weight: $font-weight-normal;
    }

    .usage-in-chart {
      font-weight: $font-weight-bold;
      font-size: $font-size-large;
      text-align: center;
    }

    &.notInitiated,
    &.pendingUpdate {
      border-color: $green !important;
    }
    &.pending,
    &.requireReschedule {
      border-color: $yellow !important;
    }
    &.rejected,
    &.rescheduleRejected {
      border-color: $red !important;
    }
    &.approved {
      border-color: $blue-100 !important;
    }

    .status-label {
      position: absolute;
      font-size: 10px;
      padding: 2px 10px;
      color: #fff;
      border-radius: 5px;
      top: 10px;
      left: 10px;

      &.notInitiated,
      &.pendingUpdate {
        background-color: $green !important;
      }
      &.pending,
      &.requireReschedule {
        background-color: $yellow !important;
        color: #333;
      }
      &.rejected,
      &.rescheduleRejected {
        background-color: $red !important;
      }
      &.approved {
        background-color: $blue-100 !important;
      }
    }

    .card-body {
      .card-text {
        background-color: white;
      }
    }
  }
}
