@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Bold.ttf');
  font-weight: 700;
}
